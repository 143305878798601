
// Libraries
import * as React from 'react'
import moment from 'moment'

// Components
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const EventTiles = ({events}) => {
	return (
		<div className='tiles'>
			<div className='event-tiles'>
				{events.map((event) =>
					<div className='event-tile'>
						<Link to={`/events/${event.event.slug}`} className='link'>
							{event.image_gatsby && <GatsbyImage
								className='event-image'
								image={ event.image_gatsby.childImageSharp.gatsbyImageData }
								alt={ `${event.event.artist.name} - ${event.event.name}` } />}
							<div className='event-child'>
								<div className='event-text-container'>
									<h3>{event.event.artist.name}</h3>
									<h4>{event.event.name}</h4>
									<p>
										{event.event.venue.name}<br/>
										{moment(event.event.starts).format('MMMM D, Y')}
									</p>
								</div>
							</div>
						</Link>
					</div>
				)}
			</div>
		</div>
	)
}

export default EventTiles
